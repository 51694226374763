@import "https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap";
:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Alegreya Sans", sans-serif;
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
  --color-white: #fff;
  --color-black: #121212;
  --color-gray-10: #f3f3f3;
  --color-gray-200: #c2c6c3;
  --color-blue-600: #002452;
  --color-azure-300: #00cfe7;
  --radius-main: 6px;
  --radius-big: 24px;
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  letter-spacing: 0;
  color: var(--color-blue-600);
  background-color: var(--color-gray-10);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.4;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
  position: relative;
}

.page__body:before {
  content: "";
  z-index: -1;
  opacity: 0;
  background-color: #27282bbf;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
}

.page.open {
  overflow: hidden;
}

.page.open > .page__body:before {
  z-index: 5;
  opacity: 1;
}

.section {
  padding: 5.375rem 0;
}

@media only screen and (max-width: 1180px) {
  .section {
    padding: 45px 0;
  }
}

@media only screen and (max-width: 992px) {
  .section {
    padding: 25px 0;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.logo {
  width: 100%;
  max-width: 104px;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .logo {
    max-width: 80px;
  }
}

.main--top {
  padding-top: var(--header-height);
}

.image {
  border-radius: var(--radius-big);
  overflow: hidden;
  box-shadow: 0 0 24px #00000005;
}

.image img {
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 992px) {
  .image img {
    aspect-ratio: 2 / 1;
  }
}

.is-shadow {
  border-radius: var(--radius-big);
  background-color: var(--color-white);
  padding: 36px;
  overflow: hidden;
  box-shadow: 0 0 24px #00000005;
}

.hidden {
  opacity: 0;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible:focus:not(.focus-visible) {
  outline: none;
}

h1 {
  font-weight: var(--fw-400);
  margin-bottom: 24px;
  font-size: clamp(2.125rem, 1.5rem + 2.7778vi, 4rem);
  line-height: 1;
}

h2, h3 {
  font-weight: var(--fw-700);
  font-size: clamp(1.5625rem, 1.3333rem + 1.0185vi, 2.25rem);
  line-height: 1.2;
}

h3 {
  margin-bottom: 16px;
  font-size: clamp(1.125rem, 1rem + .5556vi, 1.5rem);
  line-height: 1.5;
}

p {
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.slogan {
  width: -moz-fit-content;
  width: fit-content;
  font-weight: var(--fw-700);
  color: var(--color-white);
  background-color: var(--color-azure-300);
  border-radius: 100px;
  margin-bottom: 56px;
  padding: 4px 12px;
  font-size: 16px;
  line-height: 1.375;
  display: block;
  overflow: hidden;
}

@media only screen and (max-width: 992px) {
  .slogan {
    margin-bottom: 30px;
  }
}

.header {
  z-index: 10;
  width: 100%;
  -webkit-backdrop-filter: blur(6px);
  backdrop-filter: blur(6px);
  background: linear-gradient(#ffffffc2, #fff0 99.999%);
  padding: 16px 0;
  transition-property: transform, background;
  transition-duration: .3s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-200%);
}

.header.scroll {
  background: var(--color-gray-10);
  box-shadow: 0 0 2px 4px #2c2e351f;
}

.header.active {
  background: var(--color-gray-10);
}

@media only screen and (max-width: 992px) {
  .header {
    padding: 10px 0;
  }
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.nav__list {
  align-items: center;
  margin: 0 auto;
  display: flex;
}

.nav__item:not(:last-of-type) {
  margin-right: 36px;
}

.nav__link {
  font-size: 20px;
  position: relative;
}

.nav__link:before {
  content: "";
  width: 100%;
  height: 2px;
  transform-origin: 0;
  background-color: currentColor;
  transition: transform .3s linear;
  position: absolute;
  bottom: -2px;
  left: 0;
  transform: scale(0);
}

.nav__link:hover:before, .nav__link.true:before {
  transform: scale(1);
}

.nav__btns {
  align-items: center;
  gap: 24px;
  display: flex;
}

.nav--footer {
  padding: 48px 0;
}

@media only screen and (max-width: 768px) {
  .nav__inner {
    z-index: -1;
    padding-top: calc(var(--header-height) * 1.2);
    width: 100%;
    height: var(--vh);
    max-width: 350px;
    background-color: var(--color-gray-10);
    flex-direction: column;
    justify-content: flex-start;
    transition-property: transform;
    transition-duration: .3s;
    position: fixed;
    top: 0;
    right: 0;
    overflow-y: auto;
    transform: translateX(150%);
  }

  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }

  .nav__item:not(:last-child) {
    margin-right: 0;
  }

  .nav__btns {
    flex-direction: column;
  }

  .nav--footer {
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    padding: 30px 0;
  }
}

[class].btn {
  border-radius: var(--radius-main);
  width: 100%;
  max-width: -moz-fit-content;
  max-width: fit-content;
  font-family: inherit;
  font-weight: var(--fw-700);
  color: var(--color-gray-10);
  background-color: var(--color-blue-600);
  border: 2px solid #0000;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 6px 22px;
  font-size: 16px;
  line-height: 1.4;
  transition-property: background-color, color, border-color;
  transition-duration: .3s;
  display: flex;
}

[class].btn:hover, [class].btn:focus {
  border-color: var(--color-blue-600);
  background-color: var(--color-white);
  color: var(--color-blue-600) !important;
}

[class].btn--reverse {
  border-color: var(--color-blue-600);
  color: var(--color-blue-600);
  background-color: #0000;
}

[class].btn--reverse:hover, [class].btn--reverse:focus {
  background-color: var(--color-blue-600);
  color: var(--color-white) !important;
}

[class].btn--request {
  color: var(--color-gray-10) !important;
  background-color: var(--color-blue-600) !important;
}

[class].btn--request:hover, [class].btn--request:focus {
  border-color: var(--color-blue-600) !important;
  color: var(--color-black) !important;
  background-color: var(--color-white) !important;
}

[class].btn--slider {
  z-index: 2;
  background-color: #0000;
  border: none;
  padding: 0;
  position: relative;
}

[class].btn--slider:before {
  content: "";
  width: 28px;
  height: 28px;
  background-image: url("../images/icons/arrow.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
}

.burger {
  --line-height: 2px;
  --burger-size: 20px;
  --move: calc(var(--burger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-blue-600);
  width: calc(var(--burger-size)  + 5px);
  height: var(--burger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.policy__content:not(:last-child) {
  margin-bottom: 24px;
}

.policy h1 {
  margin-bottom: 3rem;
}

.policy h2 {
  margin-bottom: 24px;
  font-size: clamp(1.5625rem, 1.4583rem + .463vi, 1.875rem);
}

.policy ul {
  margin-bottom: 20px;
  padding-left: 15px;
}

.policy li {
  padding-left: 10px;
  font-size: clamp(16px, .9167rem + .3704vi, 20px);
}

.policy li:not(:last-child) {
  margin-bottom: 3px;
}

.policy li::marker {
  content: "✓";
}

.policy p:not(:last-child) {
  margin-bottom: 20px;
}

.policy a {
  color: var(--color-azure-300);
  text-decoration: underline;
}

@media only screen and (max-width: 992px) {
  .policy h1 {
    margin-bottom: 30px;
  }
}

.footer__list {
  display: flex;
}

.footer__list li:not(:last-child) {
  margin-right: 37px;
}

.footer__list li a {
  font-size: 20px;
  transition: text-shadow .3s linear;
}

.footer__list li a:hover {
  text-shadow: .3px .3px;
}

.footer__copy {
  font-weight: var(--fw-300);
  justify-content: space-between;
  margin-bottom: 16px;
  font-size: 16px;
  display: flex;
}

.footer__copy p {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  .footer__list li:not(:last-child) {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .footer__list {
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
  }

  .footer__list li:not(:last-child) {
    margin-right: 0;
  }

  .footer__copy {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }
}

.hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0 0 24px 24px;
  overflow: hidden;
}

.hero__inner {
  min-height: 900px;
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: relative;
}

.hero__content {
  z-index: 1;
  width: 100%;
  max-width: 540px;
  position: relative;
}

.hero h1 {
  margin-bottom: 24px;
}

.hero--home p {
  margin-bottom: 3rem;
}

.hero--products .hero__content span {
  display: inline-block;
}

@media only screen and (max-width: 992px) {
  .hero__inner {
    min-height: 450px;
  }

  .hero__content {
    max-width: 100%;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  .hero__inner {
    min-height: 400px;
  }

  .hero .btn {
    max-width: 100%;
  }
}

.faq {
  padding-bottom: 55px;
}

.faq__item {
  margin-bottom: 56px;
  position: relative;
}

.faq__item:before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: var(--color-azure-300);
  position: absolute;
  bottom: -24px;
}

.faq details[open] summary {
  padding-bottom: 0;
}

.faq details[open] summary:after {
  transform: scaleY(1);
}

.faq summary {
  font-weight: var(--fw-700);
  cursor: pointer;
  align-items: center;
  padding: 0 25px 0 0;
  font-size: clamp(1.125rem, 1rem + .5556vi, 1.5rem);
  line-height: 1.5;
  transition: color .3s linear;
  display: flex;
}

.faq summary:after {
  content: "";
  width: 32px;
  height: 32px;
  background-color: currentColor;
  transition: transform .3s linear;
  position: absolute;
  top: 1px;
  right: 0;
  transform: scaleY(-1);
  -webkit-mask-image: url("../images/icons/chevron.svg");
  mask-image: url("../images/icons/chevron.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.faq summary + div {
  padding: 12px 25px 0 0;
}

.faq summary:hover {
  color: var(--color-azure-300);
}

@media only screen and (max-width: 992px) {
  .faq {
    padding-bottom: 25px;
  }

  .faq__item {
    margin-bottom: 24px;
  }

  .faq__item:before {
    bottom: -14px;
  }

  .faq__item summary:after {
    top: -2px;
    right: -6px;
  }

  .faq h2 {
    margin-bottom: 30px;
  }
}

.reviews__inner {
  justify-content: space-between;
  gap: 24px;
  display: flex;
}

.reviews__content {
  width: 100%;
  max-width: 487px;
}

.reviews__content p {
  margin-bottom: 106px;
}

.reviews__list {
  width: 100%;
  max-width: 638px;
  flex-direction: column;
  gap: 36px;
  display: flex;
}

.reviews__autor {
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  display: flex;
}

.reviews__autor img {
  border-radius: 50%;
  overflow: hidden;
}

.reviews__autor h3 {
  margin-bottom: 0;
  font-size: 24px;
  line-height: 1.5;
}

.reviews .slogan {
  margin-bottom: 36px;
}

@media only screen and (max-width: 1180px) {
  .reviews__desc p {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 992px) {
  .reviews__inner {
    flex-direction: column;
  }

  .reviews__list {
    max-width: 100%;
  }

  .reviews__content {
    max-width: 100%;
    gap: 40px;
    display: flex;
  }

  .reviews__content img {
    width: 80px;
    height: 80px;
  }
}

.advantages__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  display: grid;
}

.advantages__item {
  text-align: center;
  align-self: baseline;
  place-items: center;
  padding: 24px;
  display: grid;
}

.advantages__item:before {
  content: "";
  width: 170px;
  height: 170px;
  background-image: url("../images/icons/finance.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 91px 67px;
  background-color: var(--color-azure-300);
  border-radius: 50%;
  margin-bottom: 24px;
  position: relative;
}

.advantages__item--flexebility:before {
  background-image: url("../images/icons/umbrella.svg");
  background-size: 62px 97px;
}

.advantages__item--sustainable:before {
  background-image: url("../images/icons/heart.svg");
  background-size: 101px 49px;
}

.advantages h3 {
  min-height: 42px;
  justify-content: center;
  align-items: center;
  margin-bottom: 17px;
  display: flex;
}

@media only screen and (max-width: 992px) {
  .advantages__item {
    padding: 16px;
  }

  .advantages__item:before {
    width: 110px;
    height: 110px;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .advantages__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .advantages__item:last-child {
    grid-column: 2 span;
  }

  .advantages__list {
    grid-template-columns: 1fr;
  }

  .advantages__item:last-child {
    grid-column: auto;
  }
}

.types__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 35px;
  display: grid;
}

.types__item {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.types__decs {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 36px;
  display: flex;
}

.types .btn {
  max-width: 100%;
  margin-top: auto;
}

.types h2 {
  font-weight: var(--fw-400);
  margin-bottom: 36px;
  line-height: 1.5;
}

.types h3 {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 1.4;
}

.types p {
  font-weight: var(--fw-300);
  padding: 0 20px;
  font-size: 16px;
}

@media only screen and (max-width: 992px) {
  .types__item {
    padding: 16px;
  }

  .types h2 {
    margin-bottom: 16px;
  }

  .types p {
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  .types__list {
    grid-template-columns: 1fr;
  }
}

.slider {
  padding: 5.375rem 0 6.6875rem;
}

.slider__swiper {
  overflow: hidden;
}

.slider__content {
  width: 100%;
  max-width: 638px;
}

.slider__item {
  justify-content: space-between;
  gap: 36px;
  display: flex;
}

.slider__image {
  position: relative;
}

.slider__list {
  padding-bottom: 20px;
}

.slider__nav {
  --swiper-pagination-color: var(--color-blue-600);
  width: 250px;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  position: absolute;
  left: 50%;
  transform: translate(85%, -8%);
}

.slider__nav .btn {
  position: relative;
}

.slider__nav .btn.swiper-button-disabled {
  opacity: .2;
  pointer-events: none;
}

.slider__nav .btn--prev {
  left: 30px;
  scale: -1 1;
}

.slider__nav .btn--next {
  left: auto;
  right: 30px;
}

.slider__nav .swiper-pagination {
  z-index: 1;
  position: relative;
  bottom: 0;
}

.slider__nav .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 6px);
}

.slider__desc {
  translate: 0 -34px;
}

.slider .slogan {
  margin-bottom: 282px;
}

.slider h2 {
  margin-bottom: 6px;
  line-height: 1.4;
}

@media only screen and (max-width: 1180px) {
  .slider__desc {
    translate: 0;
  }

  .slider .slogan {
    margin-bottom: 50px;
  }

  .slider {
    padding: 70px 0;
  }
}

@media only screen and (max-width: 992px) {
  .slider__nav {
    margin-inline: auto;
    position: relative;
    left: 0;
    right: 0;
    transform: none;
  }

  .slider__list {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .slider {
    padding: 30px 0;
  }

  .slider__item {
    height: 100%;
    flex-direction: column;
    gap: 24px;
  }

  .slider__content {
    max-width: 100%;
  }
}

.resources {
  padding: 5.375rem 0 5.4375rem;
}

.resources__list {
  grid-template-columns: repeat(8, 1fr);
  gap: clamp(15px, 2.5vw, 36px);
  display: grid;
}

.resources__item {
  width: 100%;
  max-width: 638px;
  padding: clamp(15px, 2.5vw, 36px);
  position: relative;
}

.resources__item:before {
  content: "";
  width: 57px;
  height: 16px;
  background-color: currentColor;
  transition: transform .3s linear;
  position: absolute;
  top: 55px;
  right: 32px;
  -webkit-mask-image: url("../images/icons/arrow-big.svg");
  mask-image: url("../images/icons/arrow-big.svg");
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: cover;
  mask-size: cover;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.resources__item a:before {
  content: "";
  position: absolute;
  inset: 0;
}

.resources__item:hover:before {
  transform: translateX(5px);
}

.resources__item--colored {
  color: var(--color-gray-10);
  background-color: var(--color-blue-600);
}

.resources__item:nth-child(1) {
  grid-area: 1 / 1 / 2 / 5;
}

.resources__item:nth-child(2) {
  grid-area: 1 / 5 / 2 / 9;
}

.resources__item:nth-child(3) {
  height: 206px;
  max-width: 750px;
  grid-area: 2 / 1 / 3 / 6;
}

.resources__item:nth-child(4) {
  height: 206px;
  grid-area: 2 / 6 / 3 / 9;
  translate: -58px;
}

.resources h2 {
  font-weight: var(--fw-400);
  margin-bottom: 24px;
  font-size: clamp(1.5625rem, 1.3333rem + 1.0185vi, 36px);
  line-height: 1.5;
}

@media only screen and (max-width: 1280px) {
  .resources__list {
    grid-template-columns: repeat(2, 1fr);
  }

  .resources__item {
    max-width: 100%;
    height: auto !important;
    grid-area: auto !important;
  }

  .resources__item:before {
    width: 45px;
    height: 12px;
    top: 10px;
  }

  .resources__item:nth-child(4) {
    translate: 0;
  }
}

@media only screen and (max-width: 1180px) {
  .resources {
    padding: 45px 0;
  }
}

@media only screen and (max-width: 992px) {
  .resources {
    padding: 25px 0;
  }
}

@media only screen and (max-width: 768px) {
  .resources__list {
    grid-template-columns: 1fr;
  }

  .resources__item:nth-child(3) {
    order: 1;
  }
}

.about {
  width: 100%;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
}

.about h2 {
  font-weight: var(--fw-400);
  margin-bottom: 36px;
  line-height: 1.5;
}

.about--products {
  max-width: 990px;
}

.about--products h2 {
  margin-bottom: 0;
}

.values__inner {
  gap: 36px;
  display: flex;
}

.values__decr {
  border-radius: var(--radius-big);
  width: 100%;
  max-width: 366px;
  color: var(--color-gray-10);
  background-color: var(--color-blue-600);
  padding: 36px;
  overflow: hidden;
  box-shadow: 0 0 24px #00000005;
}

.values__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  display: grid;
}

.values h3 {
  margin-bottom: 36px;
}

@media only screen and (max-width: 1180px) {
  .values__inner {
    align-items: center;
  }
}

@media only screen and (max-width: 992px) {
  .values__inner {
    flex-direction: column;
  }

  .values__decr, .values__item {
    max-width: 100%;
    padding: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .values__list {
    grid-template-columns: 1fr;
  }
}

.team__list {
  grid-template-columns: repeat(3, 1fr);
  gap: 36px;
  display: grid;
}

.team__item {
  text-align: center;
  align-self: baseline;
  place-items: center;
  display: grid;
}

.team__item span {
  font-weight: var(--fw-700);
  margin-bottom: 8px;
  display: block;
}

.team img {
  border-radius: 50%;
  margin-bottom: 16px;
  overflow: hidden;
}

.team h3 {
  margin-bottom: 16px;
}

@media only screen and (max-width: 992px) {
  .team__list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 576px) {
  .team__list {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .team img {
    width: 100px;
    height: 100px;
  }
}

.approach__inner {
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  display: grid;
}

.approach__text:first-of-type {
  margin-bottom: 49px;
}

.approach__image img {
  height: 100%;
}

.approach .slogan {
  margin-bottom: 83px;
}

.approach h2 {
  margin-bottom: 8px;
}

@media only screen and (max-width: 1180px) {
  .approach__text:first-of-type {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 992px) {
  .approach__inner {
    grid-template-columns: 1fr;
  }

  .approach__image img {
    aspect-ratio: 16 / 9;
  }

  .approach .slogan {
    margin-bottom: 30px;
  }
}

.introduction__list {
  display: grid;
}

.introduction__item {
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  display: grid;
}

.introduction__item:not(:first-child, :last-child) {
  padding: 86px 0;
}

.introduction__item:first-child {
  padding-bottom: 86px;
}

.introduction__item:last-child {
  padding-top: 86px;
}

.introduction__desc {
  gap: 48px;
  margin-bottom: 64px;
  display: grid;
}

.introduction__content {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.introduction__image img {
  height: 100%;
}

.introduction h2 {
  margin-bottom: 63px;
}

.introduction h3 {
  margin-bottom: 8px;
}

.introduction .btn {
  max-width: 100%;
  margin-top: auto;
}

@media only screen and (max-width: 1180px) {
  .introduction__item:not(:first-child, :last-child) {
    padding: 40px 0;
  }

  .introduction__item:first-child {
    padding-bottom: 40px;
  }

  .introduction__item:last-child {
    padding-top: 40px;
  }

  .introduction__desc {
    margin-bottom: 40px;
  }

  .introduction h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .introduction__item {
    grid-template-columns: 1fr;
  }

  .introduction__item img {
    aspect-ratio: 2 / 1;
  }

  .introduction__desc {
    gap: 24px;
  }
}

.additional__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  display: grid;
}

.additional__item--colored {
  color: var(--color-gray-10);
  background-color: var(--color-blue-600);
}

.additional__item--colored .additional__desc li:before {
  background-image: url("../images/icons/plus.svg");
}

.additional__desc {
  flex-direction: column;
  gap: 24px;
  display: flex;
}

.additional__desc li {
  padding-left: 38px;
  position: relative;
}

.additional__desc li:before {
  content: "";
  width: 24px;
  height: 24px;
  background-image: url("../images/icons/circle.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.additional h2 {
  font-weight: var(--fw-400);
  margin-bottom: 24px;
  line-height: 1.5;
}

@media only screen and (max-width: 992px) {
  .additional__list {
    grid-template-columns: 1fr;
  }

  .additional__item {
    padding: 16px;
  }

  .additional__desc {
    gap: 16px;
  }
}

.choose__list {
  gap: 56px;
  display: grid;
}

.choose__item {
  counter-increment: choose-counter;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  display: grid;
}

.choose__content:before {
  content: counter(choose-counter);
  font-weight: var(--fw-400);
  color: var(--color-azure-300);
  margin-bottom: 24px;
  font-size: 64px;
  line-height: 1;
  display: block;
  position: relative;
}

.choose__desc {
  gap: 24px;
  display: grid;
}

.choose__desc li {
  counter-increment: desc-counter;
  padding-left: 10px;
  font-size: 20px;
}

.choose__desc li:before {
  content: counters(desc-counter, ".") ".";
  font-weight: var(--fw-400);
  padding-right: 8px;
  font-size: 16px;
  line-height: 1.4;
  position: relative;
}

.choose h3 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 992px) {
  .choose__list {
    gap: 36px;
  }

  .choose__item {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  .choose__content:not(even) {
    order: -1;
  }

  .choose__desc li {
    padding-left: 0;
  }
}

.glossary__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  display: grid;
}

.glossary h3 {
  margin-bottom: 12px;
}

@media only screen and (max-width: 576px) {
  .glossary__list {
    grid-template-columns: 1fr;
    gap: 24px;
  }
}

.partners__list {
  grid-template-columns: repeat(2, 1fr);
  gap: 36px;
  display: grid;
}

.partners__item {
  text-align: center;
  align-self: baseline;
  place-items: center;
  display: grid;
  position: relative;
}

.partners__item:hover img {
  filter: grayscale();
}

.partners img {
  margin-bottom: 36px;
  transition: filter .3s linear;
}

.partners a:before {
  content: "";
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .partners__list {
    gap: 24px;
  }

  .partners img {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 576px) {
  .partners__list {
    grid-template-columns: 1fr;
  }
}

/*# sourceMappingURL=main.css.map */
