@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.introduction {
  &__list {
    display: grid;
  }

  &__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;

    &:not(:first-child, :last-child) {
      padding: 86px 0;
    }

    &:first-child {
      padding-bottom: 86px;
    }

    &:last-child {
      padding-top: 86px;
    }
  }

  &__desc {
    display: grid;
    margin-bottom: 64px;
    gap: 48px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__image {
    img {
      height: 100%;
    }
  }

  h2 {
    margin-bottom: 63px;
  }

  h3 {
    margin-bottom: 8px;
  }

  .btn {
    margin-top: auto;
    max-width: 100%;
  }

  @include media(tablet-wide) {
    &__item {
      &:not(:first-child, :last-child) {
        padding: 40px 0;
      }

      &:first-child {
        padding-bottom: 40px;
      }

      &:last-child {
        padding-top: 40px;
      }
    }

    &__desc {
      margin-bottom: 40px;
    }

    h2 {
      margin-bottom: 30px;
    }
  }

  @include media(tablet) {
    &__item {
      grid-template-columns: 1fr;

      img {
        aspect-ratio: 2/1;
      }
    }

    &__desc {
      gap: 24px;
    }
  }
}
