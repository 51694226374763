@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.team {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 36px;
  }

  &__item {
    display: grid;
    place-items: center;
    align-self: baseline;
    text-align: center;

    span {
      display: block;
      margin-bottom: 8px;
      font-weight: var(--fw-700);
    }
  }

  img {
    overflow: hidden;
    margin-bottom: 16px;
    border-radius: 50%;
  }

  h3 {
    margin-bottom: 16px;
  }

  @include media(tablet) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    img {
      width: 100px;
      height: 100px;
    }
  }
}
