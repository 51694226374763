@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.slider {
  padding: rem(86) 0 rem(107);

  &__swiper {
    overflow: hidden;
  }

  &__content {
    width: 100%;
    max-width: 638px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    gap: 36px;
  }

  &__image {
    position: relative;
  }

  &__list {
    padding-bottom: 20px;
  }

  &__nav {
    --swiper-pagination-color: var(--color-blue-600);

    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 250px;
    transform: translate(85%, -8%);

    .btn {
      position: relative;

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &--prev {
        left: 30px;
        scale: -1 1;
      }

      &--next {
        left: auto;
        right: 30px;
      }
    }

    .swiper-pagination {
      position: relative;
      bottom: 0;
      z-index: 1;
    }

    .swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 6px);
    }
  }

  &__desc {
    translate: 0 -34px;
  }

  .slogan {
    margin-bottom: 282px;
  }

  h2 {
    margin-bottom: 6px;
    line-height: 1.4;
  }

  @include media(tablet-wide) {
    // &__item {
    //   align-items: center;
    // }

    &__desc {
      translate: none;
    }

    .slogan {
      margin-bottom: 50px;
    }
  }

  @include media(tablet-wide) {
    padding: 70px 0;
  }

  @include media(tablet) {
    &__nav {
      position: relative;
      left: 0;
      right: 0;
      transform: none;
      margin-inline: auto;
    }

    &__list {
      padding-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    padding: 30px 0;

    &__item {
      flex-direction: column;
      height: 100%;
      gap: 24px;
    }

    &__content {
      max-width: 100%;
    }
  }
}
