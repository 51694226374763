@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.faq {
  padding-bottom: 55px;

  &__item {
    position: relative;
    margin-bottom: 56px;

    &::before {
      content: "";
      position: absolute;
      bottom: -24px;
      width: 100%;
      height: 1px;
      background-color: var(--color-azure-300);
    }
  }

  details {
    &[open] {
      summary {
        padding-bottom: 0;

        &::after {
          transform: scaleY(1);
        }
      }
    }
  }

  summary {
    display: flex;
    align-items: center;
    padding: 0 25px 0 0;
    font-weight: var(--fw-700);
    font-size: clamp(1.125rem, 1rem + 0.5556vi, 1.5rem);
    line-height: 1.5;
    transition: color 0.3s linear;
    cursor: pointer;

    &::after {
      @include mask;

      right: 0;
      top: 1px;
      mask-image: url("../images/icons/chevron.svg");
      width: 32px;
      height: 32px;
      transform: scaleY(-1);
      transition: transform 0.3s linear;
    }

    + div {
      padding: 12px 25px 0 0;
    }

    &:hover {
      color: var(--color-azure-300);
    }
  }

  @include media(tablet) {
    padding-bottom: 25px;

    &__item {
      margin-bottom: 24px;

      &::before {
        bottom: -14px;
      }

      summary {
        &::after {
          right: -6px;
          top: -2px;
        }
      }
    }

    h2 {
      margin-bottom: 30px;
    }
  }
}
