@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.hero {
  $root: &;
  overflow: hidden;
  border-radius: 0 0 24px 24px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 900px;
  }

  &__content {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 540px;
  }

  h1 {
    margin-bottom: 24px;
  }

  &--home {
    p {
      margin-bottom: rem(48);
    }
  }

  &--products {
    #{$root}__content {

      span {
        display: inline-block;
      }
    }
  }


  @include media(tablet) {
    &__inner {
      min-height: 450px;
    }

    &__content {
      max-width: 100%;
      margin-bottom: 0;
    }
  }

  @include media(mobile-m) {
    &__inner {
      min-height: 400px;
    }

    .btn {
      max-width: 100%;
    }
  }

}

