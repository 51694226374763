@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.glossary {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;
  }

  h3 {
    margin-bottom: 12px;
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
      gap: 24px;
    }
  }
}
