@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

html,
body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  font-style: normal;
  font-size: clamp(16px, 1vw, 20px);
  line-height: 1.4;
  letter-spacing: 0;
  color: var(--color-blue-600);
  background-color: var(--color-gray-10);
  font-optical-sizing: auto;
  scroll-padding: var(--header-height);

  &__body {
    position: relative;
    min-width: 320px;
    min-height: var(--vh);

    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      background-color: rgba(39, 40, 43, 0.75);
      opacity: 0;
      transition: opacity 0.3s;
      inset: 0;
    }
  }

  &.open {
    overflow: hidden;

    > .page__body::before {
      z-index: 5;
      opacity: 1;
    }
  }
}

.section {
  padding: rem(86) 0;

  @include media(tablet-wide) {
    padding: 45px 0;
  }

  @include media(tablet) {
    padding: 25px 0;
  }
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
}

.site-container {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 1fr auto;
  min-height: var(--vh);
}

.logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 104px;

  @include media(tablet) {
    max-width: 80px;
  }
}

.main {
  &--top {
    padding-top: var(--header-height);
  }
}

.image {
  overflow: hidden;
  border-radius: var(--radius-big);
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.02);

  img {
    width: 100%;
    height: 100%;

    @include media(tablet) {
      aspect-ratio: 2/1;
    }
  }
}

.is-shadow {
  overflow: hidden;
  border-radius: var(--radius-big);
  padding: 36px;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.02);
  background-color: var(--color-white);
}
