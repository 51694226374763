@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: 16px 0;
  width: 100%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.76), rgba(255, 255, 255, 0) 99.999%);
  transition-property: transform, background;
  transition-duration: 0.3s;
  backdrop-filter: blur(6px);

  &.hide {
    transform: translateY(-200%);
  }

  &.scroll {
    box-shadow: 0 0 2px 4px rgba(44, 46, 53, 0.12);
    background: var(--color-gray-10);
  }

  &.active {
    background: var(--color-gray-10);
  }

  @include media(tablet) {
    padding: 10px 0;
  }
}
