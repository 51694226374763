@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.choose {
  &__list {
    display: grid;
    gap: 56px;
  }

  &__item {
    counter-increment: choose-counter;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;
  }

  &__content {
    &::before {
      content: counter(choose-counter);
      position: relative;
      display: block;
      margin-bottom: 24px;
      font-weight: var(--fw-400);
      font-size: 64px;
      line-height: 1;
      color: var(--color-azure-300);
    }
  }

  &__desc {
    display: grid;
    gap: 24px;

    li {
      counter-increment: desc-counter;
      padding-left: 10px;
      font-size: 20px;

      &::before {
        content: counters(desc-counter, ".") ".";
        position: relative;
        padding-right: 8px;
        font-weight: var(--fw-400);
        font-size: 16;
        line-height: 1.4;
      }
    }
  }

  h3 {
    margin-bottom: 24px;
  }

  @include media(tablet) {
    &__list {
      gap: 36px;
    }

    &__item {
      grid-template-columns: 1fr;
      gap: 24px;
    }

    &__content {
      &:not(even) {
        order: -1;
      }
    }

    &__desc {
      li {
        padding-left: 0;
      }
    }
  }
}
