@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.resources {
  padding: rem(86) 0 rem(87);

  &__list {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    gap: clamp(15px, 2.5vw, 36px);
  }

  &__item {
    position: relative;
    padding: clamp(15px, 2.5vw, 36px);
    width: 100%;
    max-width: 638px;

    &::before {
      @include mask;

      right: 32px;
      top: 55px;
      mask-image: url("../images/icons/arrow-big.svg");
      width: 57px;
      height: 16px;
      transition: transform 0.3s linear;
    }

    a {
      &::before {
        content: "";
        position: absolute;
        inset: 0;
      }
    }

    &:hover {
      &::before {
        transform: translateX(5px);
      }
    }

    &--colored {
      color: var(--color-gray-10);
      background-color: var(--color-blue-600);
    }

    &:nth-child(1) {
      grid-area: 1 / 1 / 2 / 5;
    }

    &:nth-child(2) {
      grid-area: 1 / 5 / 2 / 9;
    }

    &:nth-child(3) {
      grid-area: 2 / 1 / 3 / 6;
      height: 206px;
      max-width: 750px;
    }

    &:nth-child(4) {
      grid-area: 2 / 6 / 3 / 9;
      height: 206px;
      translate: -58px 0;
    }
  }

  h2 {
    margin-bottom: 24px;
    font-weight: var(--fw-400);
    font-size: clamp(1.5625rem, 1.3333rem + 1.0185vi, 36px);
    line-height: 1.5;
  }

  @include media(desktop) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      grid-area: auto !important;
      height: auto !important;
      max-width: 100%;

      &::before {
        top: 10px;
        width: 45px;
        height: 12px;
      }

      &:nth-child(4) {
        translate: none;
      }
    }
  }

  @include media(tablet-wide) {
    padding: 45px 0;
  }

  @include media(tablet) {
    padding: 25px 0;
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:nth-child(3) {
        order: 1;
      }
    }
  }
}
