@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.approach {
  &__inner {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;
  }

  &__text {
    &:first-of-type {
      margin-bottom: 49px;
    }
  }

  &__image {
    img {
      height: 100%;
    }
  }

  .slogan {
    margin-bottom: 83px;
  }

  h2 {
    margin-bottom: 8px;
  }

  @include media(tablet-wide) {
    &__text {
      &:first-of-type {
        margin-bottom: 25px;
      }
    }
  }

  @include media(tablet) {
    &__inner {
      grid-template-columns: 1fr;
    }

    &__image {
      img {
        aspect-ratio: 16/9;
      }
    }

    .slogan {
      margin-bottom: 30px;
    }
  }
}
