@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.advantages {
  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }

  &__item {
    display: grid;
    place-items: center;
    align-self: baseline;
    padding: 24px;
    text-align: center;

    &::before {
      content: "";
      position: relative;
      margin-bottom: 24px;
      border-radius: 50%;
      width: 170px;
      height: 170px;
      background-image: url("../images/icons/finance.svg");
      background-position: center;
      background-size: 91px 67px;
      background-repeat: no-repeat;
      background-color: var(--color-azure-300);
    }

    &--flexebility {
      &::before {
        background-image: url("../images/icons/umbrella.svg");
        background-size: 62px 97px;
      }
    }

    &--sustainable {
      &::before {
        background-image: url("../images/icons/heart.svg");
        background-size: 101px 49px;
      }
    }
  }

  h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 17px;
    min-height: 42px;
  }

  @include media(tablet) {
    &__item {
      padding: 16px;

      &::before {
        margin-bottom: 16px;
        width: 110px;
        height: 110px;
      }
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__item {
      &:last-child {
        grid-column: 2 span;
      }
    }
  }

  @include media(mobile-l) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-child {
        grid-column: auto;
      }
    }
  }
}
