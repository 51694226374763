@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.types {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 35px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  &__decs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
    text-align: center;
    gap: 15px;
  }

  .btn {
    margin-top: auto;
    max-width: 100%;
  }

  h2 {
    margin-bottom: 36px;
    font-weight: var(--fw-400);
    line-height: 1.5;
  }

  h3 {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.4;
  }

  p {
    padding: 0 20px;
    font-weight: var(--fw-300);
    font-size: 16px;
  }

  @include media(tablet) {
    &__item {
      padding: 16px;
    }

    h2 {
      margin-bottom: 16px;
    }

    p {
      padding: 0;
    }
  }

  @include media(mobile-m) {
    &__list {
      grid-template-columns: 1fr;
    }
  }
}
