@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: 36px;
    }
  }

  &__link {
    position: relative;
    font-size: 20px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: -2px;
      width: 100%;
      height: 2px;
      background-color: currentcolor;
      transform: scale(0);
      transform-origin: left;
      transition: transform 0.3s linear;
    }

    &:hover {
      &::before {
        transform: scale(1);
      }
    }

    &.true {
      &::before {
        transform: scale(1);
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &--footer {
    padding: 48px 0;
  }

  @include media(mobile-l) {
    &__inner {
      position: fixed;
      right: 0;
      top: 0;
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding-top: calc(var(--header-height) * 1.2);
      width: 100%;
      height: var(--vh);
      max-width: 350px;
      background-color: var(--color-gray-10);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.3s;
    }

    &__list {
      z-index: -1;
      flex-direction: column;
      margin: 0;
    }

    &__item {
      margin-bottom: 30px;

      &:not(:last-child) {
        margin-right: 0;
      }
    }

    &__btns {
      flex-direction: column;
    }

    &--footer {
      flex-direction: column;
      justify-content: center;
      padding: 30px 0;
      gap: 24px;
    }
  }
}
