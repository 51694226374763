/* stylelint-disable declaration-no-important */
@use "../utils/functions/" as *;
@use "../utils/mixins" as *;

*[class].btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 2px solid transparent;
  border-radius: var(--radius-main);
  padding: 6px 22px;
  width: 100%;
  max-width: fit-content;
  font-family: inherit;
  font-weight: var(--fw-700);
  font-size: 16px;
  line-height: 1.4;
  color: var(--color-gray-10);
  background-color: var(--color-blue-600);
  transition-property: background-color, color, border-color;
  transition-duration: 0.3s;

  &:hover,
  &:focus {
    border-color: var(--color-blue-600);
    color: var(--color-blue-600) !important;
    background-color: var(--color-white);
  }

  &--reverse {
    border-color: var(--color-blue-600);
    color: var(--color-blue-600);
    background-color: transparent;

    &:hover,
    &:focus {
      color: var(--color-white) !important;
      background-color: var(--color-blue-600);
    }
  }

  &--request {
    color: var(--color-gray-10) !important;
    background-color: var(--color-blue-600) !important;

    &:hover,
    &:focus {
      border-color: var(--color-blue-600) !important;
      color: var(--color-black) !important;
      background-color: var(--color-white) !important;
    }
  }

  &--slider {
    position: relative;
    z-index: 2;
    border: none;
    padding: 0;
    background-color: transparent;

    &::before {
      content: "";
      position: absolute;
      width: 28px;
      height: 28px;
      background-image: url("../images/icons/arrow.svg");
      background-position: 0 0;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}
