/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */

@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,800;0,900;1,100;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");

:root {
  --content-width: 1312px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Alegreya Sans", sans-serif;

  // font-weight
  --fw-300: 300;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;

  //
  --color-white: #fff;
  --color-black: #121212;
  --color-gray-10: #f3f3f3;
  --color-gray-200: #c2c6c3;
  --color-blue-600: #002452;
  --color-azure-300: #00cfe7;

  //  radius
  --radius-main: 6px;
  --radius-big: 24px;
}
