@use "../utils/functions/" as *;
@use "../utils/mixins/" as *;

h1 {
  margin-bottom: 24px;
  font-weight: var(--fw-400);
  font-size: clamp(2.125rem, 1.5rem + 2.7778vi, 4rem);
  line-height: 1;
}

h2,
h3 {
  font-weight: var(--fw-700);
  font-size: clamp(1.5625rem, 1.3333rem + 1.0185vi, 2.25rem);
  line-height: 1.2;
}

h3 {
  margin-bottom: 16px;
  font-size: clamp(1.125rem, 1rem + 0.5556vi, 1.5rem);
  line-height: 1.5;
}

p {
  font-size: clamp(16px, 0.9167rem + 0.3704vi, 20px);
}

.slogan {
  display: block;
  overflow: hidden;
  margin-bottom: 56px;
  border-radius: 100px;
  padding: 4px 12px;
  width: fit-content;
  font-weight: var(--fw-700);
  font-size: 16px;
  line-height: 1.375;
  color: var(--color-white);
  background-color: var(--color-azure-300);

  @include media(tablet) {
    margin-bottom: 30px;
  }
}
