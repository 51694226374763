@use "../../utils/functions/" as *;
@use "../../utils/mixins/" as *;

.additional {
  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 36px;
  }

  &__item {
    &--colored {
      color: var(--color-gray-10);
      background-color: var(--color-blue-600);

      .additional__desc {
        li {
          &::before {
            background-image: url("../images/icons/plus.svg");
          }
        }
      }
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    gap: 24px;

    li {
      position: relative;
      padding-left: 38px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        width: 24px;
        height: 24px;
        background-image: url("../images/icons/circle.svg");
        background-position: 0 0;
        background-size: cover;
        background-repeat: no-repeat;
        transform: translateY(-50%);
      }
    }
  }

  h2 {
    margin-bottom: 24px;
    font-weight: var(--fw-400);
    line-height: 1.5;
  }

  @include media(tablet) {
    &__list {
      grid-template-columns: 1fr;
    }

    &__item {
      padding: 16px;
    }

    &__desc {
      gap: 16px;
    }
  }
}
